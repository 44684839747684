import { template as template_8cfc019846974193831f4dab5d6dcaf9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8cfc019846974193831f4dab5d6dcaf9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
