import { template as template_9916428890a146fd9a9db6f17f71d679 } from "@ember/template-compiler";
const FKText = template_9916428890a146fd9a9db6f17f71d679(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
